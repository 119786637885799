import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

const initialState = {
  now: Date.now(),
  // November 29th 2023 at 5:00 PM EST
  saleEnds: 1701295200000,
  // Close the sale 3 hours after the listed time
  extendedBy: 1000 * 60 * 60 * 3,
};

function countdownReducer(state = initialState, action) {
  switch (action.type) {
    case 'TICK': {
      return {
        ...state,
        now: action.now,
      };
    }

    default: {
      return state;
    }
  }
}

export const getSaleEnds = (state) => {
  return state.countdown.saleEnds;
};

export const getTimeRemaining = (state) => {
  const regularEndIn = state.countdown.saleEnds - state.countdown.now;
  const twilightEndIn =
    state.countdown.saleEnds +
    state.countdown.extendedBy -
    state.countdown.now;

  if (regularEndIn > 0) {
    return regularEndIn;
  } else if (twilightEndIn > 0) {
    return twilightEndIn;
  } else {
    return 0;
  }
};

export const getIsTwilightHour = (state) => {
  const regularEndIn = state.countdown.saleEnds - state.countdown.now;
  const twilightEndIn =
    state.countdown.saleEnds +
    state.countdown.extendedBy -
    state.countdown.now;

  return regularEndIn < 0 && twilightEndIn > 0;
};

export const getTimeRemainingInHumanTerms = (state) => {
  const isTwilightHour = getIsTwilightHour(state);
  const endsAt = isTwilightHour
    ? state.countdown.saleEnds + state.countdown.extendedBy
    : state.countdown.saleEnds;

  const endDate = new Date(endsAt);
  const nowDate = new Date(state.countdown.now);

  return {
    days: differenceInDays(endDate, nowDate),
    hours: differenceInHours(endDate, nowDate) % 24,
    minutes: differenceInMinutes(endDate, nowDate) % 60,
    seconds: differenceInSeconds(endDate, nowDate) % 60,
  };
};

export default countdownReducer;
