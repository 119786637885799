export default (store) => (next) => {
  // Don't run on the server
  if (typeof window === 'undefined') {
    return () => {};
  }

  window.setInterval(() => {
    next({
      type: 'TICK',
      now: Date.now(),
    });
  }, 1000);

  return (action) => {
    next(action);
  };
};
