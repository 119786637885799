import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import 'typeface-sriracha';
import 'focus-visible';

import { THEME } from '@constants';
import configureStore from '../../store';

import GlobalStyles from '@components/GlobalStyles';

const store = configureStore();

const App = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={THEME}>
        {children}
        <GlobalStyles />
        <div id="confetti-portal" />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
