import React from "react";
import App from "@components/App";

export const wrapPageElement = ({ element }) => {
  return <App>{element}</App>;
};

export const shouldUpdateScroll = ({ routerProps }) => {
  const disableScrollUpdate = routerProps?.location?.state?.disableScrollUpdate;
  return !disableScrollUpdate;
};
