import { createStore, applyMiddleware, compose } from 'redux';

import countdownMiddleware from './middlewares/countdown.middleware';
import rootReducer from './reducers';

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(countdownMiddleware)
  );

  return store;
}
